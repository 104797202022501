import ClassNames from 'classnames';
import Image from 'components/image';
import {Link} from 'gatsby';
import Logo from 'images/common/logo.png';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Style from 'styles/components/header.module.scss';

const Header = ({siteTitle}) => {
  const [toggle, setToggle] = useState(false);
  const [homeActive, setHomeActive] = useState(false);
  const [serviceActive, setServiceActive] = useState(false);
  const [aboutActive, setAboutActive] = useState(false);
  const [recruitActive, setRecruitActive] = useState(false);
  const [contactActive, setContactActive] = useState(false);
  const [privacyActive, setPrivacyActive] = useState(false);

  const hamburgerMenuClass = ClassNames({
    [Style.hamburgerMenu]: true,
    [Style.active]: toggle,
  });

  const overlayClass = ClassNames({
    [Style.overlay]: true,
    [Style.open]: toggle,
  });

  const homeClass = ClassNames({
    [Style.navBackground__item]: true,
    [Style.navBackground__item__isActive]: homeActive,
  });

  const serviceClass = ClassNames({
    [Style.navBackground__item]: true,
    [Style.navBackground__item__isActive]: serviceActive,
  });

  const aboutClass = ClassNames({
    [Style.navBackground__item]: true,
    [Style.navBackground__item__isActive]: aboutActive,
  });

  const recruitClass = ClassNames({
    [Style.navBackground__item]: true,
    [Style.navBackground__item__isActive]: recruitActive,
  });

  const contactClass = ClassNames({
    [Style.navBackground__item]: true,
    [Style.navBackground__item__isActive]: contactActive,
  });

  const privacyClass = ClassNames({
    [Style.navList__item]: true,
    [Style.navList__privacy]: true,
  });

  const comachiLogoClass = ClassNames({
    [Style.navList__item]: true,
    [Style.navList__comachiLogo]: true,
  });

  const handleClick = () => {
    setToggle(!toggle);
  };

  return (
    <header>
      <div className={Style.hdInfo}>
        <div className={Style.hdInfo__inner}>
          <Link to='/'>
            <img src={Logo} className={Style.hdInfo__logo} alt='logo' />
          </Link>
        </div>

        <div className={hamburgerMenuClass} id='toggle' onClick={handleClick}>
          <span className={Style.top}></span>
          <span className={Style.middle}></span>
          <span className={Style.bottom}></span>
        </div>

        <div className={overlayClass} id='overlay' onClick={handleClick}>
          <div className={Style.navBackground}>
            <div className={homeClass} data-nav-background='1'></div>
            <div className={serviceClass} data-nav-background="2"></div>
            <div className={aboutClass} data-nav-background='3'></div>
            <div className={recruitClass} data-nav-background="4"></div>
            <div className={contactClass} data-nav-background='5'></div>
          </div>
          <nav>
            <ul className={Style.navList}>
              <li
                className={Style.navList__item}
                onMouseEnter={() => setHomeActive(!homeActive)}
                onMouseLeave={() => setHomeActive(!homeActive)}
              >
                <Link className={Style.navList__link} to='/'>
                  HOME
                </Link>
              </li>
              <li
                className={Style.navList__item}
                onMouseEnter={() => setServiceActive(!serviceActive)}
                onMouseLeave={() => setServiceActive(!serviceActive)}
              >
                <Link className={Style.navList__link} to='/service/'>
                  SERVICE
                </Link>
              </li>
              <li
                className={Style.navList__item}
                onMouseEnter={() => setAboutActive(!aboutActive)}
                onMouseLeave={() => setAboutActive(!aboutActive)}
              >
                <Link className={Style.navList__link} to='/about/'>
                  ABOUT
                </Link>
              </li>
              <li
                className={Style.navList__item}
                onMouseEnter={() => setRecruitActive(!recruitActive)}
                onMouseLeave={() => setRecruitActive(!recruitActive)}
              >
                <Link className={Style.navList__link} to='/recruit/'>
                  RECRUIT
                </Link>
              </li>
              <li
                className={Style.navList__item}
                onMouseEnter={() => setContactActive(!contactActive)}
                onMouseLeave={() => setContactActive(!contactActive)}
              >
                <Link className={Style.navList__link} to='/contact/'>
                  CONTACT
                </Link>
              </li>
              <li
                className={privacyClass}
                onMouseEnter={() => setPrivacyActive(!privacyActive)}
                onMouseLeave={() => setPrivacyActive(!privacyActive)}
              >
                <Link className={Style.navList__link} to='/privacy/'>
                  PRIVACY POLICY
                </Link>
              </li>
              <li
                className={comachiLogoClass}
                onMouseEnter={() => setPrivacyActive(!privacyActive)}
                onMouseLeave={() => setPrivacyActive(!privacyActive)}
              >
                <Link to='/comachicart/'>
                  <Image className={Style.navList__comachiLogoImg} filename='bnr_menu_comachi.png' />
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};
Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
