import {PresignedPost} from 'aws-sdk/clients/s3';
import classNames from 'classnames';
import Image from 'components/image';
import {Link} from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';
import React, {useState} from 'react';
import Style from 'styles/components/footer.module.scss';

const Footer = () => {
  const [showSmileBanner, setShowSmileBanner] = useState(false);
  const [once, setOnce] = useState(false);
  const hoverBanner = () => {
    if (!once) {
      setShowSmileBanner(true);
    }
  };
  const leaveBanner = () => {
    setShowSmileBanner(false);
    setOnce(true);
  };
  const bannerClass = classNames({
    [Style.ftCon__comachiLink__img]: true,
    [Style.ftCon__comachiLink__img__disable]: showSmileBanner,
  });
  const smileBannerClass = classNames({
    [Style.ftCon__comachiLink__img]: true,
    [Style.ftCon__comachiLink__img__disable]: !showSmileBanner,
  });
  return (
    <footer>
      <div className={Style.ftCon}>
        <p className={Style.pageTop} onClick={() => scrollTo('#root')}>
          TOP
        </p>
        <div className={Style.ftCon__leftInner}>
          <div className={Style.ftCon__companyName}>株式会社イーゼ</div>
          <p className={Style.ftCon__address}>東京都千代田区神田小川町3-6-1 栄信ビル7階</p>
          <ul className={Style.ftNavList}>
            <li className={Style.ftNavList__item}>
              <Link className={Style.ftNavList__link} to="/service/">Service</Link>
            </li>
            <li className = {Style.ftNavList__item}>
              <Link className = {Style.ftNavList__link} to = '/about/'>About</Link>
            </li>
            <li className = {Style.ftNavList__item}>
              <Link className = {Style.ftNavList__link} to = '/recruit/'>Recruit</Link>
            </li>
            <li className = {Style.ftNavList__item}>
              <Link className = {Style.ftNavList__link} to = '/contact/'>Contact</Link>
            </li>
            <li className = {Style.ftNavList__item}>
              <Link className = {Style.ftNavList__privacy} to = '/privacy/'>PrivacyPolicy</Link>
            </li>
          </ul>
          <div className={Style.ftCon__comachiLink}>
            <Link to="/comachicart/" onMouseEnter={hoverBanner} onMouseLeave={leaveBanner}> 
            <Image className = {bannerClass} filename = 'bnr_footer_comachi.png' />
            </Link>
          </div>
          <div className={Style.ftCon__copyright}>Copyright© e-ize Co.Ltd. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
