/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import "normalize.css";
import Header from "components/header";
import Footer from "components/footer";
import Style from "styles/components/layout.module.scss";
import "typeface-lato";
// import ComachiLink from "components/comachi_link";

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div id="root">
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <main className={Style.main}>{children}</main>
      {/* コマチへの誘導リンク。実装方法を詰めてから実装しなおし。不要な場合は削除する */}
      {/* <ComachiLink /> */}
      <Footer />
    </div>
  );
};

export default Layout;
